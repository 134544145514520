import React from "react"

import Layout from "../components/UI/Layout/layout"
import SEO from "../components/seo"
import SpaceTop from "../components/UI/SpaceTop"
import { Container } from "../components/UI/Grid"
import SectionTitle from "../components/UI/SectionTitle"
import TypedFont from "../components/TypedFont"
import CustomTable from "../components/UI/Table"
import Contact from "../components/Contact"

import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import BlockQuote from "../components/UI/Quote"
import GaLink from "../components/UI/Ga-link"


const AppDevelopmentPage = () => (
  <Layout>
    <SEO
      metaTitle="App Entwicklung für hybride Apps"
      metaDescription="Platformunabhängige Mobile App Entwicklung für Hybride, Web oder Cross-Platform Apps mit den Technologien Ionic Framework und React Native"
      metaKeywords="ionic framework, react, angular, react native, firebase, cross-platform, web, mobile, hybrid, iOS, Android"
    />

    <SpaceTop />
    <Container>

      <SectionTitle className="section-title" leftAlign={true} UniWidth="100%">
        <TypedFont tag="h1">App Entwicklung</TypedFont>
        <BlockQuote><TypedFont delay>Transparenz in eigener Sache.</TypedFont></BlockQuote>
      </SectionTitle>

      <Fade>
        <h2>Cross-Platform-Development mit <GaLink to="blog?tag=ionic" category="backlink" site="app-entwicklung" value="blog/tag/ionic" style={{ color: '#4e8ef7' }}>Ionic Framework</GaLink> & <GaLink to="blog?tag=reactnative" category="backlink" site="app-entwicklung" value="blog/tag/reactnative" style={{ color: '#53c1de' }}>React Native</GaLink></h2>
        <h3 style={{ marginTop: '20px' }}>
          Wir haben bereits etliche Cross-Platform Apps vom Konzept, Design & Entwicklung bis zur Ausspielung in die jeweiligen Stores gebracht,
          für dich könnten wir das auch tun!
        </h3>
      </Fade>

      <Fade>
        <h3 style={{ marginTop: '20px' }}>Wo liegen im Kern die Unterschiede zu nativen Apps</h3>
      </Fade>


      <fade>
        <CustomTable>
          <thead>
            <tr>
              <th></th>
              <th>Native</th>
              <th>
                <GaLink to="blog?tag=reactnative" category="backlink" site="app-entwicklung" value="blog/tag/reactnative" style={{ color: '#53c1de' }}>
                  React Native
                </GaLink>
              </th>
              <th>
                <GaLink to="blog?tag=ionic" category="backlink" site="app-entwicklung" value="blog/tag/ionic" style={{ color: '#4e8ef7' }}>
                  Ionic Framework
                </GaLink>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Codebase</td>
              <td>eine eigene</td>
              <td>1</td>
              <td>1</td>
            </tr>
            <tr>
              <td>Sprache</td>
              <td>Swift & Kotlin</td>
              <td>JS (React)</td>
              <td>JS (React, Angular, Vue, Pure)</td>
            </tr>
            <tr>
              <td>Performance</td>
              <td>Am besten</td>
              <td>Sehr gut</td>
              <td>gut</td>
            </tr>
            <tr>
              <td>Entwicklungsdauer</td>
              <td>Lang</td>
              <td>Schneller</td>
              <td>am schnellsten</td>
            </tr>
            <tr>
              <td>Zugang Nativer Funktionen</td>
              <td>am besten</td>
              <td>sehr gut</td>
              <td>sehr gut</td>
            </tr>
            <tr>
              <td>Kosten</td>
              <td>Teuer</td>
              <td>günstiger</td>
              <td>am günstigsten</td>
            </tr>
            <tr>
              <td>Platformen</td>
              <td>iOS oder Android</td>
              <td>iOS und Android</td>
              <td>iOS, Android, & Web (PWA)</td>
            </tr>

          </tbody>
        </CustomTable>
      </fade>


      <Fade duration={3000}>
        <SectionTitle className="section-title" leftAlign={true} UniWidth="100%">
          <h2><GaLink to="blog?tag=ionic" category="backlink" site="app-entwicklung" value="blog/tag/ionic" style={{ color: '#4e8ef7' }}>Ionic Framework</GaLink></h2>

          <BlockQuote>
            Write once, run anywhere
          </BlockQuote>
        </SectionTitle>
      </Fade>


      <Fade>
        <h2>Dieses Zitat beschreibt die Magie & Flexibilität von Ionic.</h2>
        <br />
        <h2>
          Einmal geschrieben kann die APP in iOS, Android & sogar im <span className="highlight">Web</span> in einem nativen Container der jeweiligen Systeme ausgespielt werden.
          Diese Eigenschaft hebt dieses <span className="highlight">UI-Framework</span> von anderen Cross-Platform Frameworks ab.
        </h2>
        {/* <h2>
          Durch <a target="_blank" rel="noopener noreferrer" href="https://cordova.apache.org/">Cordova</a> & <a href="https://capacitor.ionicframework.com/" target="_blank" rel="noopener noreferrer">Capacitor</a> hat man darüber Hinaus
          noch vollen Zugriff auf alle Nativen Schnittstellen zu den Endgeräten. Push, Kamera, etc. stellen also kein Problem dar.
        </h2> */}
        {/* <h2>
          Früher hat sich Ionic in der Entwicklung auf Angular beschränkt. Seit Ionic 4 ist es möglich zwischen Angular, React, Vue oder Pure (Vanilla)
          Javascript zu entscheiden.
        </h2> */}
        {/* <br />
        <h2>
          Im Grunde ist Ionic nur ein Framework welches Komponenten bereitstellt um die Web Applikation im nativen Look aussehen zu lassen, am Ende
          unter der Haube wird die APP in einem nativen Container in der WebView der jeweiligen Systeme ausgespielt.
        </h2> */}
        <br />
        <h2>Bekannte Apps mit Ionic sind im Blog zu finden</h2>

      </Fade>

      <SectionTitle className="section-title" leftAlign={true} UniWidth="100%" style={{ marginTop: '100px' }}>
        <Fade>
          <div style={{ position: 'relative' }}>
            {/* <SVG style={{ marginTop: '50px' }} width="150px" height="auto" src={LogoReact}></SVG> */}
            <h2><GaLink to="blog?tag=reactnative" category="backlink" site="app-entwicklung" value="blog/tag/reactnative" style={{ color: '#53c1de' }}>React Native</GaLink></h2>
          </div>
          <BlockQuote>
            Learn once - write everywhere
          </BlockQuote>
        </Fade>
      </SectionTitle>

      <Fade>
        <h2>
          Mit React Native schreiben  wir <span className="highlight">echte Native</span> Apps die wir
          mit einer Code Basis für iOS & Android auspielen können. React Native erfreut sich großer Beliebtheit und hat eine riesen Community.
          Es gibt fast für jede Anforderung ein eigenes Package, eine direkte Lösung und macht es auch sehr flexibel.
        </h2>
        <br />
        <h2>
          Weitere bekannte Apps sind im Blog zu finden
        </h2>
      </Fade>

      <Fade>
        <SectionTitle className="section-title" leftAlign={true} UniWidth="100%" style={{ marginTop: '100px' }}>
          <h2> <GaLink to="blog?tag=ionic" category="backlink" site="app-entwicklung" value="blog/tag/ionic" style={{ color: '#4e8ef7' }}>Ionic</GaLink> & <GaLink to="blog?tag=reactnative" category="backlink" site="app-entwicklung" value="blog/tag/reactnative" style={{ color: '#53c1de' }}>React Native</GaLink></h2>
        </SectionTitle>
      </Fade>

      <Fade>
        <BlockQuote>
          Die Frage ist nicht <u>Was</u> besser ist, die Frage ist <u>Wann</u> was besser ist.
        </BlockQuote>
      </Fade>

      <Fade>
        <CustomTable>
          <thead>
            <tr>
              <th></th>
              <th>
                <GaLink to="blog?tag=reactnative" category="backlink" site="app-entwicklung" value="blog/tag/reactnative" style={{ color: '#53c1de' }}>React Native</GaLink>
              </th>
              <th>
                <GaLink to="blog?tag=ionic" category="backlink" site="app-entwicklung" value="blog/tag/ionic" style={{ color: '#4e8ef7' }}>Ionic Framework</GaLink>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Release</td>
              <td>2015</td>
              <td>2013</td>
            </tr>
            <tr>
              <td>Github Stars (05.2020)</td>
              <td>86.4k</td>
              <td>40.8k</td>
            </tr>
            <tr>
              <td>Developers</td>
              <td>Facebook</td>
              <td>Drifty.co</td>
            </tr>
            <tr>
              <td>Programmiersprache</td>
              <td>React (Javascript)</td>
              <td>Web Technologien - HTML, CSS & JS (Angular, React, Vue)</td>
            </tr>
            <tr>
              <td>GUI</td>
              <td>Native UI Controllers</td>
              <td>(HTML & CSS) Pre-Styled Components</td>
            </tr>
          </tbody>
        </CustomTable>
      </Fade>

      <Fade>
          <h2 style={{ marginBottom: '50px' }}>
            Für mehr Informationen zu den einzelnen Technologien darfst du gerne auf meinem <GaLink style={{color:'#FB7B81'}} to="blog" category="backlink" site="app-entwicklung" value="blog">Blog</GaLink> vorbeischauen.
          </h2>
        </Fade>

      <Fade>
        <h2>Du weißt nicht welche der beiden Frameworks besser zu deinem Projekt, deinen Anforderungen passt? Sprich mich einfach an.</h2>
      </Fade>
      <Fade>
        <Contact page='app'></Contact>

      </Fade>

    </Container>
  </Layout>
)

export default AppDevelopmentPage
